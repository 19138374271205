import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import EcnButton from "../components/StyledComponents/EcnButton";
import EcnInput from "../components/StyledComponents/EcnInput";
import { userForgotPassword } from "../redux/slices/userSlices/userForgotPasswordSlice";
import { useNavigate } from "react-router-dom";
import { correctEmail } from "../helpers/Validations/BasicValidations";
import { Icon } from "@iconify/react";
import { trackMixpanelEvents } from "../helpers/Analytics/mixpanelProxyApi";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;
const REACT_APP_RESET_PASSWORD = process.env.REACT_APP_RESET_PASSWORD_URL;
const SIGNIN_REDIRECT = process.env.REACT_APP_AUTH_REDIRECT;

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { forgotPasswordResponse } = useAppSelector(
    (state) => state.forgotPassword
  );
  const { userInfo } = useAppSelector((state: any) => state.userDetails);

  const [email, setEmail] = useState<string>("");
  const [isEmailSent, seIsEmailSent] = useState(false);
  const [resendTime, setResendTime] = useState<number>(0);

  useEffect(() => {
    if (resendTime > 0) {
      setTimeout(() => {
        setResendTime((prev) => prev - 1);
      }, 1000);
    }
  }, [resendTime]);

  const sendForgotPasswordHandler = () => {
    if (email !== "" && correctEmail.test(email)) {
      trackMixpanelEvents({
        eventName: "ENTERPRISE_ADMIN_SIGNIN_FORGOT_PASSWORD_INITIATION",
        url: window.location.href,
        distinctId: email,
        properties: {},
      });
      dispatch(
        userForgotPassword({
          email: email,
          redirect_url: REACT_APP_RESET_PASSWORD,
          enterprise: true,
        })
      );
      setResendTime(20);
      seIsEmailSent(true);
    }
  };

  const backHandler = () => {
    window.location.href = `${SIGNIN_REDIRECT}?origin=${window?.location.href}`;
  };

  return (
    <div className="forgot-password">
      <div className="container">
        <div className="left">
          <div className="wrapper">
            <img
              src="/new_logo_black.png"
              alt="Elevate"
              className="elevate-logo"
            />

            <div className="content-wrapper">
              <span className="back" onClick={backHandler}>
                {" "}
                <Icon icon="ep:back" width={24} height={24} />
                Back
              </span>{" "}
              <h2>Forgot password</h2>
              <p className="sub-text">
                Enter your email address to reset your password
              </p>
              <div className="field">
                <p>Email</p>
                <EcnInput
                  className="ecn-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  error={email !== "" && !correctEmail.test(email)}
                />
              </div>
              {!isEmailSent ? (
                <EcnButton
                  variant="contained"
                  className="btn-contained cta-btn"
                  onClick={sendForgotPasswordHandler}
                  disabled={email === "" || !correctEmail.test(email)}
                >
                  Send email
                </EcnButton>
              ) : resendTime <= 0 ? (
                <EcnButton
                  variant="contained"
                  className="btn-contained cta-btn"
                  onClick={sendForgotPasswordHandler}
                  disabled={email === "" || !correctEmail.test(email)}
                >
                  Resend email
                </EcnButton>
              ) : (
                <p className="helper-link">
                  Thank you! If there's an account associated with this email,
                  we've sent instructions to reset your password.
                  <br />
                  Please check your inbox.!
                  <br /> Resend in {resendTime} sec
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="right">
          <img
            src={IMG_BASE + "/enterprise/signup_placeholder.png"}
            alt="Placeholder"
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
